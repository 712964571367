<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <div class="flex-fill p-3">
      <b-row class="border-bottom">
        <b-col cols="12">
          <p class="h5 mb-2 font-weight-bold">Change Password</p>
          <div class="text-muted">
            <p class="mb-0">You have reached this page with a temporary password.</p>
            <p class="mb-0">You need to re-set your password NOW.</p>
            <p class="mb-0">To do this, please enter your one-time password in the first box below.</p>
            <p class="mb-0">Enter a new password in the second box below. The password must be at least 8 characters long and contain at least 1 capital and 1 number.</p>
            <p class="mb-4">Repeat this new password in the third box to confirm it and then click submit.</p>
          </div>

          <b-form-group label="Current Password" :invalid-feedback="validationErrors['currentPassword']" :state="!(validationErrors['currentPassword'])">
            <b-form-input type="password" v-model="currentPassword" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="New Password" :invalid-feedback="validationErrors['newPassword']" :state="!(validationErrors['newPassword'])">
            <b-form-input type="password" v-model="newPassword" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Confirm New Password" :invalid-feedback="validationErrors['newPasswordConfirm']" :state="!(validationErrors['newPasswordConfirm'])">
            <b-form-input type="password" v-model="newPasswordConfirm" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="flex flex-row">
        <div class="flex-fill"></div>
        <b-button class="mt-2" @click.prevent="reset" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">Save</span>
        </b-button>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: { },
  data() {
    return {
      validationErrors: {},
      saving: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    };
  },

  methods: {
    ...mapActions('auth', ['changePassword', 'check']),
    reset() {
      this.saving = true;

      this.changePassword({
        current_password: this.currentPassword,
        password: this.newPassword,
        password_confirmation: this.newPasswordConfirm
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;

        this.check().then(() => {
          // this.$router.replace('/');
          window.location.href = '/';
        });
      }).catch(err => {
        this.saving = false;
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error changing your password, please try again!';
        }
      });
    },
  }
}
</script>

<style>
</style>
